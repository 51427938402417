<template>
  <div class="my-10">
    <SelaTab :length-tab="2" @tab-change="resetCategoryId">
      <template #trigger-tab-1="{ activeIndex, activeTab }">
        <div @click="activeTab(1)">
          <div :class="{ 'style-trigger-tab': activeIndex === 1 }">
            {{ t('materials') }}
          </div>
        </div>
      </template>
      <template #trigger-tab-2="{ activeIndex, activeTab }">
        <div @click="activeTab(2)">
          <div :class="{ 'style-trigger-tab': activeIndex === 2 }">
            {{ t('stores') }}
          </div>
        </div>
      </template>
      <template #content-trigger-tab-1>
        <MaterialsCatogriesWithSupportFilter
          v-if="isMobileOrTablet"
          :support-filter="true"
          :store-type-id="selectedStoreType?.id"
          style-filter="w-full"
          :categories="categories || undefined"
          :fetch-params="{}"
        >
          <template #Categories="{ categories: categoriesProps }">
            <StoreTypesCategoryList :categories="categoriesProps" />
          </template>
        </MaterialsCatogriesWithSupportFilter>
      </template>
      <template #content-trigger-tab-2>
        <StoreTypesStoreTypeList />
      </template>
      <template #tab-1>
        <div class="w-full mx-auto">
          <MaterialsCatogriesWithSupportFilter
            v-if="!isMobileOrTablet"
            :support-filter="true"
            style-filter="w-full"
            :store-type-id="selectedStoreType?.id"
            :categories="categories || undefined"
            :fetch-params="{}"
          >
            <template #Categories="{ categories: categoriesProps }">
              <StoreTypesCategoryList :categories="categoriesProps" />
            </template>
          </MaterialsCatogriesWithSupportFilter>
          <MaterialsGrid
            :items-per-page="20"
            with-query-params
            :fetch-params="{ store_type_id: route.query.store_type_id }"
            class="mb-5"
            manual
          />
        </div>
      </template>
      <template #tab-2>
        <div class="h-8"></div>
        <StoresGrid
          :key="keyStoresGrid"
          class="sela-container mb-5"
          :fetch-params="{
            orderBy: 'id',
            store_type_id: route.query.store_type_id
          }"
          :with-query-params="false"
        />
      </template>
    </SelaTab>
  </div>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()
const { isMobileOrTablet } = useDevice()
const { storeTypes } = useStoreTypes()

const keyStoresGrid = ref<number>(Math.random())
const route = useRoute()
const categories = ref<Category[] | null>(null)
const selectedStoreType = computed(() =>
  storeTypes.value?.find(
    (el) => el.id.toString() === route.query.store_type_id?.toString()
  )
)
getCategories()
watch(
  () => route.query.store_type_id,
  () => {
    getCategories()
    keyStoresGrid.value = Math.random()
  }
)
useServerSeoMeta(
  useOgMeta(
    url + '/store-type',
    t('store_types'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)
function resetCategoryId() {
  useQueryNavigation({ category_id: undefined })
}
function getCategories() {
  categories.value = selectedStoreType?.value?.parent_categories ?? []
}
useHead({
  title: t('store_types'),
  link: [useSeoCanonical(url + '/store-type')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('store_types'), url + '/store-type')
    ])
  ]
})

</script>
<style>
.style-trigger-tab {
  @apply font-bold text-green-800 border-b-4 pb-2 border-green-800 w-max mx-auto;
}

</style>
