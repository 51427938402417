<template>
  <div v-if="storeTypesParents?.length">
    <SelaSwiper
      :key="`StoreTypeList-StoreTypeListSlider`"
      :items="storeTypesParents"
      :auto-slides="true"
      :auto-play="false"
    >
      <template #slide="{ item }">
        <div :key="`store-filter-order-${item.name}`">
          <div
            class="text-sm text-black cursor-pointer px-1 mb-3 w-[3.8rem] sm:w-[4.3rem] md:w-[4.8rem] lg:w-[5.3rem]"
            @click="goToType(item.id)"
          >
            <div
              class="p-2 rounded-md mb-1 aspect-square"
              :class="[
                item.id.toString() === route.query.store_type_id?.toString()
                  ? 'bg-button_color'
                  : 'bg-nav_color bg-opacity-20    hover:bg-button_color'
              ]"
            >
              <div class="w-10 md:w-14 mx-auto aspect-square">
                <NuxtImg
                  provider="cacheIpx"
                  preset="modified"
                  :src="item.image"
                  :alt="item.name"
                  width="60"
                  height="60"
                  class="aspect-square object-cover"
                />
              </div>
            </div>
            <div
              class="w-full text-center text-[0.60rem] sm:text-[0.65rem] md:text-[0.70rem] lg:text-[0.75rem]"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </template>
    </SelaSwiper>
  </div>
</template>

<script setup lang="ts">
const { storeTypesParents } = useStoreTypes()
function goToType(id: number) {
  if (id.toString() !== route.query.store_type_id?.toString()) {
    useQueryNavigation({ store_type_id: id })
  }
}
const route = useRoute()
</script>
