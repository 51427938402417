<template>
  <div class="relative h-auto  max-w-screen-xl mx-auto" style="min-height: 300px">
    <div class="sticky top-[40px] sm:top-[80px] z-10 bg-bg_color">
      <div id="swiper-tabs-trigger" :class="stylePublic">
        <div v-for="index in lengthTab" :key="`tab-item-trigger-${index}`" class="w-1/2 text-center">
          <slot
            :name="`trigger-tab-${index}`"
            :active-index="currentTabIndex"
            :active-tab="activeTab"
          />
        </div>
      </div>
      <div class="h-3"></div>

      <slot
        :name="`content-trigger-tab-${currentTabIndex}`"
      />
    </div>
    <div>
      <div
        id="swiper-tabs"
        ref="mainSwiperRef"
        :dir="localeProperties.dir"
        class="swiper z-0"
      >
        <div class="swiper-wrapper w-full h-full">
          <div
            v-for="index in lengthTab"
            :key="`tab-item-${index}`"
            class="swiper-slide"
          >
            <div class="w-full">
              <slot
                class="w-full"
                :name="`tab-${index}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper/bundle'
import type { SwiperOptions } from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'
const { localeProperties } = useI18n()
const swiper = ref<Swiper | null>(null)
const mainSwiperRef = ref<HTMLElement | null>(null)
withDefaults(
  defineProps<{
    lengthTab: number
    stylePublic?: string
  }>(),
  {
    stylePublic: 'flex text-lg  justify-around  cursor-pointer border-b'
  }
)
const emits = defineEmits(['tab-change'])
onMounted(() => {
  if (mainSwiperRef.value) {
    swiper.value = new Swiper(mainSwiperRef.value, {
      autoplay: undefined,
      speed: 1000,
      loop: false,
      slidesPerView: 1,
      initialSlide: 0
    })

    swiper.value.on('realIndexChange', (e) => {
      emits('tab-change', e.realIndex)
      const swiperTabsContainer = document.getElementById('swiper-tabs-trigger')
      if (swiperTabsContainer) {
        const top = swiperTabsContainer.offsetTop
        window.scrollTo(0, top)
      }
      currentTabIndex.value = e.realIndex + 1
    })
  }
})

const currentTabIndex = ref<number>(1)
function activeTab(index: number) {
  swiper.value?.slideToLoop(index - 1)
}
</script>

<style>
.swiper, swiper-container {
  @apply h-full !important;
}.swiper-slide swiper-slide-active {
  @apply mx-auto !important;
}
.swiper-slide,swiper-slide {
  flex-shrink: 0 !important;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  transition-property: transform !important;
  display: block !important;
}
</style>
